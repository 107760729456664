import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { socialMedia } from '@config';
import { loaderDelay, navDelay } from '@utils';
import { Icon } from '@components/icons';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;

  h1 {
    margin: 0 0 30px 4px;
    color: ${({ theme }) => theme.colors.green};
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-family: ${({ theme }) => theme.fonts.SFMono};
    font-weight: 400;

    @media (${({ theme }) => theme.bp.tabletL}) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
  }

  h2,
  h3 {
    margin: 0;
    font-size: 80px;
    line-height: 0.9;

    @media (${({ theme }) => theme.bp.tabletL}) {
      font-size: 60px;
    }
    @media (${({ theme }) => theme.bp.mobileL}) {
      font-size: 40px;
    }
  }

  h3 {
    margin: 10px 0 0;
    color: ${({ theme }) => theme.colors.slate};
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
  }

  .social-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
    margin-right: 1rem;
    height: 5rem;
    width: 5rem;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hello <span role="img" aria-label="wave">👋</span> I'm</h1>;
  const two = <h2>Tamim</h2>;
  const three = <h3> </h3>;// <h3>I write code</h3>;
  const four = (
    <p>
      I'm a 3<sup>rd</sup> year <a href="https://cs.uwaterloo.ca/" target="_blank" rel="noopener noreferrer">
      Computer Science
    </a> student at the <a href="https://uwaterloo.ca/" target="_blank" rel="noopener noreferrer">
      University of Waterloo
    </a>
    </p>
  );
  const five = (
    socialMedia &&
    socialMedia.map(({ url, name }, i) => (
      <a href={url} aria-label={name} key={i} className="social-link" target="_blank" rel="noopener noreferrer">
        <Icon name={name}/>
      </a>
    ))
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      <TransitionGroup component={null}>
        {isMounted &&
        items.map((item, i) => (
          <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
            <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
