import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const StyledJobsSection = styled.section`
  .inner {
    display: flex;
    flex-direction: column;

    @media (${({ theme }) => theme.bp.tabletS}) {
      display: block;
    }
  }
`;

const StyledTabContent = styled.div`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 30px;

  @media (${({ theme }) => theme.bp.tabletL}) {
    padding-left: 20px;
  }
  @media (${({ theme }) => theme.bp.tabletS}) {
    padding-left: 0;
  }

  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    font-weight: 500;
    margin-bottom: 5px;

    .company {
      color: ${({ theme }) => theme.colors.green};
    }
  }

  .range {
    color: ${({ theme }) => theme.colors.lightSlate};
    font-family: ${({ theme }) => theme.fonts.SFMono};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    margin-bottom: 30px;
  }
`;

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  return (
    <StyledJobsSection id="jobs">
      <h2 className="heading">Where I’ve Worked</h2>

      <div className="inner">
        {jobsData &&
        jobsData.map(({ node }, i) => {
          const { frontmatter, html } = node;
          const { title, url, company, range } = frontmatter;

          return (
            <CSSTransition key={i} timeout={250} classNames="fade">
              <StyledTabContent
                id={`panel-${i}`}
                role="tabpanel"
                aria-labelledby={`tab-${i}`}
              >
                <h3>
                  <span>{title}</span>
                  <span className="company">
                      &nbsp;@&nbsp;
                    <a href={url} className="inline-link" target="_blank" rel="noopener noreferrer">
                        {company}
                      </a>
                    </span>
                </h3>

                <p className="range">{range}</p>

                {/*<div dangerouslySetInnerHTML={{ __html: html }} />*/}
              </StyledTabContent>
            </CSSTransition>
          );
        })}
      </div>
    </StyledJobsSection>
  );
};

export default Jobs;
