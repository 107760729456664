import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import styled from 'styled-components';
import {loaderDelay} from '@utils';

const StyledSideElement = styled.div`
  width: 40px;
  position: fixed;
  bottom: 0;
  left: ${props => (props.orientation === 'left' ? '40px' : 'auto')};
  right: ${props => (props.orientation === 'left' ? 'auto' : '40px')};
  z-index: 10;
  color: ${({theme}) => theme.colors.lightSlate};

  @media (${({theme}) => theme.bp.desktopS}) {
    left: ${props => (props.orientation === 'left' ? '20px' : 'auto')};
    right: ${props => (props.orientation === 'left' ? 'auto' : '20px')};
  }

  @media (${({theme}) => theme.bp.tabletL}) {
    display: none;
  }
`;

const Side = ({children, isHome, orientation}) => {
  return (
    <StyledSideElement orientation={orientation}>
      <TransitionGroup component={null}>
        <CSSTransition classNames={isHome ? 'fade' : ''} timeout={isHome ? loaderDelay : 0}>
          {children}
        </CSSTransition>
      </TransitionGroup>
    </StyledSideElement>
  );
};

Side.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
  orientation: PropTypes.string,
};

export default Side;
