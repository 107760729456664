import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { socialMedia, srConfig } from '@config';
import sr from '@utils/sr';
import { Icon } from '@components/icons';

const StyledContactSection = styled.section`
  text-align: center;
  max-width: 600px;
  margin: 0 auto 100px;

  @media (${({ theme }) => theme.bp.tabletL}) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    color: ${({ theme }) => theme.colors.green};
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-family: ${({ theme }) => theme.fonts.SFMono};
    font-weight: 400;
    margin-bottom: 20px;
    justify-content: center;
    @media (${({ theme }) => theme.bp.desktopS}) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }

    &:before {
      bottom: 0;
      font-size: ${({ theme }) => theme.fontSizes.sm};
      @media (${({ theme }) => theme.bp.desktopS}) {
        font-size: ${({ theme }) => theme.fontSizes.xs};
      }
    }
    &:after {
      display: none;
    }
  }

  .title {
    font-size: 60px;

    @media (${({ theme }) => theme.bp.desktopS}) {
      font-size: 50px;
    }
    @media (${({ theme }) => theme.bp.tabletL}) {
      font-size: 40px;
    }
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

  .social-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
    margin-right: 1rem;
    height: 5rem;
    width: 5rem;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        Check out my <a href={socialMedia[0].url} target="_blank" rel="noopener noreferrer">Github</a> for more things I
        have built. Currently looking for internship opportunities. Feel free to
        get in touch with me on <a href={socialMedia[1].url} target="_blank"
                                   rel="noopener noreferrer">LinkedIn</a>. <span role="img" aria-label="smile">😃</span>
      </p>

      {
        socialMedia &&
        socialMedia.map(({ url, name }, i) => (
          <a href={url} aria-label={name} key={i} className="social-link" target="_blank" rel="noopener noreferrer">
            <Icon name={name}/>
          </a>
        ))
      }
    </StyledContactSection>
  );
};

export default Contact;
